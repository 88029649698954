<template>
  <div id="parkingDetailDialog">
    <el-dialog
        title="停车详情"
        :visible.sync="show"
        width="1000px"
        @open="getList"
    >
      <el-table
          :data="table.tableData"
          style="width: 100%"
          v-loading="table.loading"
          row-key="parkingRecordId"
          lazy
          :load="load"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :key="table.loading"
      >
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
                scope.row.plateNum
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
                scope.row.plateExtNum
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column prop="inChannelName" label="入口" align="center">
        </el-table-column>
        <el-table-column prop="arrivalTime" label="入场时间" align="center">
        </el-table-column>
        <el-table-column label="入场图片" align="center">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.arrivalImage"
                style="width: 70px; height: 70px"
                :src="scope.row.arrivalImage"
                fit="contain"
                :preview-src-list="[scope.row.arrivalImage]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="outChannelName" label="出口" align="center">
        </el-table-column>
        <el-table-column prop="departureTime" label="出场时间" align="center">
        </el-table-column>
        <el-table-column label="出场图片" align="center">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.departureImage"
                style="width: 70px; height: 70px"
                :src="scope.row.departureImage"
                fit="contain"
                :preview-src-list="[scope.row.departureImage]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="departureType" label="离场类型" align="center">
        </el-table-column>
        <el-table-column prop="unlicensed" label="无牌车" align="center">
        </el-table-column>
        <el-table-column prop="parkingStatus" label="停车状态" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="getRecordDetail(scope.row.parkingRecordId)"
                v-if="buttonList.includes('parkingRecord-recordDetail')"
            >记录详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <recordDialog ref="recordDialog"/>
  </div>
</template>

<script>
export default {
  components: {
    recordDialog: () => import("./recordDialog.vue"),
  },
  data() {
    return {
      show: false,
      parkingRecordId: "",
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  methods: {
    getRecordDetail(parkingRecordId) {
      this.$refs.recordDialog.parkingRecordId = parkingRecordId;
      this.$refs.recordDialog.show = true;
    },
    async load(tree, treeNode, resolve) {
      let res = await this.$http.get(
          `/parking/record/list/${tree.parkingRecordId}/child`
      );
      if (res.code === 0) {
        for (let item of res.data) {
          item.hasChildren = item.hasChildren === "是";
          item.inChannelName = item.inChannelName ? item.inChannelName : "--";
          item.outChannelName = item.outChannelName
              ? item.outChannelName
              : "--";
          item.departureType = item.departureType ? item.departureType : "--";
          item.departureTime = item.departureTime
              ? this.$moment(item.departureTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
          item.arrivalTime = item.arrivalTime
              ? this.$moment(item.arrivalTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
          if (!item.plateNum || !item.plateExtNum) {
            item.isSamePlateNum = true;
          } else {
            item.isSamePlateNum = item.plateNum === item.plateExtNum;
          }
        }
        resolve(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.get(
            `/parking/record/detail/${this.parkingRecordId}`
        );
        if (res.code === 0) {
          if (!res.data) {
            return;
          }
          res.data = [res.data];
          for (let item of res.data) {
            item.hasChildren = item.hasChildren === "是";
            item.inChannelName = item.inChannelName ? item.inChannelName : "--";
            item.outChannelName = item.outChannelName
                ? item.outChannelName
                : "--";
            item.departureType = item.departureType ? item.departureType : "--";
            item.departureTime = item.departureTime
                ? this.$moment(item.departureTime).format("yyyy-MM-DD HH:mm:ss")
                : "--";
            item.arrivalTime = item.arrivalTime
                ? this.$moment(item.arrivalTime).format("yyyy-MM-DD HH:mm:ss")
                : "--";
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.table.tableData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#parkingDetailDialog {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
